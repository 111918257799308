import React from "react";


const ProgressBar = (props) => {
  const { bgcolor, completed } = props;
  const containerStyles = {
    height: 25,
    width: '200px',
    backgroundColor: "#e0e0de",
    borderRadius: 50,
  }

  const fillerStyles = {
    height: '100%',
    width: `${completed}%`,
    backgroundColor: bgcolor,
    borderRadius: 'inherit',
    textAlign: 'right'
  }

  const labelStyles = {
    padding: 5,
    color: 'white',
    fontWeight: 'bold',
    fontSize: '18px',
  }
  return (
    <div style={containerStyles}>
    <div style={fillerStyles}>
      <span style={labelStyles}>{`${completed}%`}</span>
    </div>
  </div>
  );
};

export default ProgressBar;